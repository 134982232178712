
.container {
  padding-top: 20px;
  padding-bottom: 100px;
}

.clear {
  clear: both;
  display: block;
}
#list-content {
  background: #fff;
  border-radius: 10px;
  padding: 5px 10px;

  .word-row {
    border-bottom: 1px solid rgb(238, 238, 238);
    &:last-child {
      border: 0;
    }
  }
}
